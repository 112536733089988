import { createSlice } from "@reduxjs/toolkit";

// import axios from "axios";

import { setAlert } from "./components-slice";

import { getRequestError } from "../../utils/functions";
import { API_URLS, getRequest, postRequest } from "../../api";

type Props = {
  myDomains: [];
};
export const initialState: Props = {
  myDomains: [],
};

// Slice
const slice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    getMyDomainsSuccess: (state, { payload }) => {
      state.myDomains = payload;
    },
  },
});
export default slice.reducer;

// Actions
const { getMyDomainsSuccess } = slice.actions;

export const getMyDomainsAction = () => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: API_URLS?.getDomains,
      params: null,
    });

    const data = res?.data;
    dispatch(getMyDomainsSuccess(data));

    return { success: data ? true : false };
  } catch (error) {
    const errorMessage = getRequestError(error);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getDomainAvailabilityAction =
  (name: string) => async (dispatch: any) => {
    try {
      const res = await getRequest({
        url: `${API_URLS?.getDomainAvailability}/${name}`,
        params: null,
      });

      const data = res?.data;

      return { success: data ? true : false, data };
    } catch (error) {
      const errorMessage = getRequestError(error);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const postPrebookDomainAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await postRequest({
      url: API_URLS?.prebookDomain,
      data,
      params: null,
    });

    const successRes = res?.status === 200;
    return { success: successRes ? true : false, data: res?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getPaymentStatusAction = (id: string) => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: `${API_URLS?.getPaymentStatus}/${id}`,
      params: null,
    });

    const data = res?.data;

    return { success: data ? true : false, data };
  } catch (error) {
    const errorMessage = getRequestError(error);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const getNFTAction = (domain: string) => async (dispatch: any) => {
  try {
    const res = await getRequest({
      url: `${API_URLS?.getNft}/${domain}`,
      params: null,
    });

    const data = res?.data;

    return { success: data ? true : false, data };
  } catch (error) {
    const errorMessage = getRequestError(error);
    dispatch(setAlert(true, "error", errorMessage));
  }
};
