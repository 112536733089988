import { useAccount } from "wagmi";
import { useAppDispatch } from "../redux";

import { logoutUserAction } from "../redux/slices/auth-slice";

import Icon from "../assets/svg";

const Topbar = () => {
  const account = useAccount();
  const dispatch = useAppDispatch();

  const walletEllipsis = (address?: string) => {
    if (address && address.length > 19) {
      const firstPart = address.substring(0, 10);
      const lastPart = address.substring(address.length - 9);
      return `${firstPart}...${lastPart}`;
    } else {
      return address;
    }
  };

  return (
    <div className="topbar_container">
      <div className="user">
        <Icon name="user" />
        <p>{walletEllipsis(account?.address)}</p>
      </div>

      <div className="icon close" onClick={() => dispatch(logoutUserAction())}>
        <Icon name="logout" />
      </div>
    </div>
  );
};

export default Topbar;
