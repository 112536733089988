import OnboardingPage from "./onboarding";
import ReservePage from "./reserve";

type Props = {
  label: string;
  to?: string | null;
  component?: () => JSX.Element;
  unlist?: boolean;
}[];

const Navigations: Props = [
  {
    label: "ONBOARDING",
    to: "/onboarding",
    component: OnboardingPage,
    unlist: true,
  },
  {
    label: "RESERVE",
    to: "/reserve",
    component: ReservePage,
  },
];

export default Navigations;
