import { useEffect } from "react";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

const PrivacyPage = () => {
  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home_page_container privacy_page_container">
      <Navbar />

      <div className="privacy_policy">
        <div className="side"></div>

        <div className="content">
          <h1>Privacy Policy</h1>
          <hr />
          <br />
          <br />
          <p>
            <strong>Effective Date</strong>: 06/18/2024
          </p>
          <br />
          <br />
          <p>
            IDCV ("we," "our," or "us") is committed to protecting your privacy.
            This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you use our services, including our
            website, application, and any related services (collectively, the
            "Services").
          </p>
          <br />
          <br />
          <h2>1. Information We Collect</h2>
          <br />
          <p>
            <strong>Personal Information</strong>: When you register for an
            account, use our Services, or contact us, we may collect personal
            information such as your name, email address, phone number, and
            payment information.
          </p>
          <br />
          <p>
            <strong>Usage Data</strong>: We automatically collect information
            about your interactions with the Services, such as IP address,
            browser type, operating system, referring URLs, and access times.
          </p>
          <br />
          <br />
          <h2>2. How We Use Your Information</h2>
          <br />
          <p>We may use the information we collect to:</p>
          <br />
          <ul>
            <li>Provide, maintain, and improve our Services.</li>
            <li>
              Process transactions and send related information, including
              purchase confirmations and invoices.
            </li>
            <li>
              Communicate with you, including responding to your comments,
              questions, and requests.
            </li>
            <li>
              Monitor and analyze usage and trends to improve your experience
              with the Services.
            </li>
            <li>
              Personalize and improve the Services, including providing tailored
              content and recommendations.
            </li>
            <li>
              Protect against, investigate, and deter fraudulent, unauthorized,
              or illegal activity.
            </li>
          </ul>
          <br />
          <br />
          <h2>3. Sharing Your Information</h2>
          <br />
          <p>We may share your information in the following circumstances:</p>
          <br />
          <p>
            <strong>With Your Consent</strong>: We may share or disclose your
            information with your consent.
          </p>
          <br />
          <p>
            <strong>Service Providers</strong>: We may share your information
            with third-party vendors and service providers who perform services
            on our behalf, such as payment processing, data analysis, email
            delivery, hosting services, and customer service.
          </p>
          <br />
          <p>
            <strong>Legal Requirements</strong>: We may disclose your
            information if required to do so by law or in response to valid
            requests by public authorities (e.g., a court or a government
            agency).
          </p>
          <br />
          <br />
          <h2>4. Security of Your Information</h2>
          <br />
          <p>
            We implement appropriate technical and organizational measures to
            protect the security of your personal information. However, no
            internet or email transmission is ever fully secure or error-free.
            Please keep this in mind when disclosing any personal information to
            us via the internet.
          </p>
          <br />
          <br />
          <h2>5. Your Choices</h2>
          <br />
          <p>
            <strong>Account Information</strong>: You may update or correct your
            account information at any time by logging into your account or
            contacting us directly.
          </p>
          <br />
          <p>
            <strong>Marketing Communications</strong>: You can opt-out of
            receiving promotional emails from us by following the instructions
            in those emails or by contacting us. If you opt-out, we may still
            send you non-promotional communications, such as those about your
            account or our ongoing business relations.
          </p>
          <br />
          <br />
          <h2>6. Children’s Privacy</h2>
          <br />
          <p>
            Our Services are not intended for use by children under the age of
            13, and we do not knowingly collect personal information from
            children under 13. If we learn that we have collected personal
            information from a child under 13, we will take steps to delete such
            information from our files as soon as possible.
          </p>
          <br />
          <br />
          <h2>7. Changes to This Privacy Policy</h2>
          <br />
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPage;
