// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAMmjhZ91Zu5YeU0Zs9pMNg181r8YZaYHc",
  authDomain: "idcv-core.firebaseapp.com",
  projectId: "idcv-core",
  storageBucket: "idcv-core.appspot.com",
  messagingSenderId: "675787042770",
  appId: "1:675787042770:web:2f445b0f86b4642dcfa20b",
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
