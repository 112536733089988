import { useEffect } from "react";
import { useAppDispatch } from "../redux";
import classNames from "classnames";

import Icon from "../assets/svg";

import { closeAlert } from "../redux/slices/components-slice";

type Props = {
  show: boolean;
  className: string;
  text: string;
};

const Alert = ({ show, className, text }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(closeAlert());
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(`alert_container ${className}`, {
        show,
      })}
    >
      <div className="alert">
        <p>{text}</p>
      </div>

      <div className="close_div" onClick={() => dispatch(closeAlert())}>
        <Icon name="close" />
      </div>
    </div>
  );
};

export default Alert;
