import { Link } from "react-router-dom";

import Logo from "../components/logo";

import { uk } from "../assets/img";

const Navbar = () => {
  return (
    <div className="navbar_container">
      <Link to="/">
        <Logo />
      </Link>

      <div className="flex_end">
        <div className="language">
          <img src={uk} alt="Language icon" />
          <p>EN</p>
        </div>

        <Link to="/register" className="btn_secondary">
          Get your id.cv
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
