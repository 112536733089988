import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux";

import AuthSide from "../components/auth-side";
import Topbar from "../components/topbar";

import NavRoutes from "./routes";

import { logoutUserAction } from "../redux/slices/auth-slice";

const Dashboard = () => {
  const account = useAccount();
  const dispatch = useAppDispatch();

  const { alert } = useSelector((state: any) => state.componentsSlice);

  useEffect(() => {
    if (account?.isDisconnected || alert?.message?.includes("valid")) {
      dispatch(logoutUserAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, alert]);

  return (
    <BrowserRouter>
      <div className="dashboard">
        <AuthSide />

        <div className="dashboard_content">
          <Topbar />

          <NavRoutes />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Dashboard;
