import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firebase";

import Button from "./button";

const GoogleAuth = ({
  isLogin,
  onSubmit,
  isSubmitting,
  setIsSubmitting,
}: {
  isLogin?: boolean;
  onSubmit: (profile: any) => void;
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
}) => {
  const provider = new GoogleAuthProvider();

  const loginUser = async () => {
    setIsSubmitting(true);

    await signInWithPopup(auth, provider)
      .then((userCredential: any) => {
        onSubmit(userCredential?.user?.accessToken);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage, "errorMessage");

        setIsSubmitting(false);
      });
  };

  return (
    <Button
      className="btn_tertiary"
      onClick={() => loginUser()}
      loading={isSubmitting}
      disabled={isSubmitting}
      leftIcon="google"
    >
      {isLogin ? "Sign in" : "Sign up"} with Google
    </Button>
  );
};

export default GoogleAuth;
