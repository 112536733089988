import { Arrow } from "./arrow";
import { Cart } from "./cart";
import { ChevronRight } from "./chevron-right";
import { Close } from "./close";
import { Dots } from "./dots";
import { ErrorCircle } from "./error-circle";
import { Facebook } from "./facebook";
import { Google } from "./google";
import { Instagram } from "./instagram";
import { LinkedIn } from "./linkedin";
import { Loader } from "./loader";
import { Logout } from "./logout";
import { Occupation } from "./occupation";
import { Plus } from "./plus";
import { Search } from "./search";
import { Twitter } from "./twitter";
import { User } from "./user";

const Icon = ({ name }: { name: string }) => {
  switch (name) {
    case "arrow":
      return <Arrow />;
    case "cart":
      return <Cart />;
    case "chevronRight":
      return <ChevronRight />;
    case "close":
      return <Close />;
    case "dots":
      return <Dots />;
    case "errorCircle":
      return <ErrorCircle />;
    case "facebook":
      return <Facebook />;
    case "google":
      return <Google />;
    case "instagram":
      return <Instagram />;
    case "linkedin":
      return <LinkedIn />;
    case "loader":
      return <Loader />;
    case "logout":
      return <Logout />;
    case "occupation":
      return <Occupation />;
    case "plus":
      return <Plus />;
    case "search":
      return <Search />;
    case "twitter":
      return <Twitter />;
    case "user":
      return <User />;
    default:
      return <Close />;
  }
};

export default Icon;
