import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Dojah from "react-dojah";

const OnboardingPage = () => {
  const history = useHistory();

  const { user } = useSelector((state: any) => state.authSlice);

  const appID = process.env.REACT_APP_DOJAH_APP_ID;
  const publicKey = process.env.REACT_APP_DOJAH_PUB_KEY;
  const widgetID = process.env.REACT_APP_DOJAH_WIDG_ID;
  const type = "custom";

  const config = {
    widget_id: widgetID,
  };

  const userData = {
    first_name: user?.user?.displayName,
    email: user?.user?.email,
  };

  const metadata = {
    email: user?.user?.email,
    firebaseUid: user?.user?.firebaseUid,
  };

  const response = (type: string) => {
    if (type === "success") {
      history.push("/reserve");
    }
  };

  return (
    <div className="page_container onboarding_page_container">
      <Dojah
        response={response}
        appID={appID}
        publicKey={publicKey}
        type={type}
        config={config}
        userData={userData}
        govData={{}}
        metadata={metadata}
      />
    </div>
  );
};

export default OnboardingPage;
