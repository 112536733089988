import { Link } from "react-router-dom";

import Icon from "../assets/svg";
import logo from "../assets/svg/logo.svg";

import { socialOnClick } from "../utils/functions";

const Footer = () => {
  const socials: any = [
    // { name: "facebook", handle: "id.cv" },
    // { name: "instagram", handle: "id.cv" },
    // { name: "linkedin", handle: "id.cv" },
    // { name: "twitter", handle: "id.cv" },
  ];

  const telegramLink = "https://rebrand.ly/idcvtelegram";

  return (
    <div className="footer">
      <div className="info">
        <Link to="/">
          <img src={logo} alt="Logo Img" />
        </Link>
        <p>All your data are belong to you.</p>

        <div className="socials">
          {socials?.map((item: any, i: number) => (
            <div key={i} className="icon" onClick={() => socialOnClick(item)}>
              <Icon name={item?.name} />
            </div>
          ))}
        </div>
      </div>

      <div className="links">
        <h6>Products</h6>

        <Link to="/">id.cv</Link>
        <Link to="/">Profile Builder</Link>
        <Link to="/">My Data</Link>
      </div>

      <div className="links">
        <h6>Company</h6>

        <a href={telegramLink} target="_blank" rel="noreferrer">
          About Us
        </a>
        <Link to="/">Blog</Link>
      </div>

      <div className="links">
        <h6>FAQ</h6>

        <a href={telegramLink} target="_blank" rel="noreferrer">
          Help Center
        </a>
        <a href={telegramLink} target="_blank" rel="noreferrer">
          FAQ
        </a>
      </div>

      <div className="links">
        <h6>Legal</h6>

        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/">Terms of Service</Link>
      </div>

      <div className="animated_text">
        <p>
          All your data are belong to you 🖤 <span>✦</span> All your data are
          belong to you 🖤 <span>✦</span> All your data are belong to you 🖤{" "}
          <span>✦</span> All your data are belong to you 🖤 <span>✦</span> All
          your data are belong to you 🖤 <span>✦</span> All your data are belong
          to you 🖤 <span>✦</span> All your data are belong to you 🖤{" "}
          <span>✦</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
