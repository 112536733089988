import { useEffect } from "react";

import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import Icon from "../assets/svg";
import { secure } from "../assets/img";

const HomePage = () => {
  useEffect(() => {
    window.scroll(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const telegramLink = "https://rebrand.ly/idcvtelegram";

  return (
    <div className="home_page_container">
      <div className="hero_section">
        <Navbar />

        <div className="hero_content">
          <p className="built">ALL YOUR DATA ARE BELONG TO YOU 🖤</p>
          <h1>
            Earn when your profile or resume is viewed or contacted. <br />
            Next-gen profiles on blockchain.
          </h1>
          <p className="sub_title">
            Reserve your name to mint id.cv soul bound NFT and get IDCV token
            airdrops when we launch.
          </p>

          <Link to="/register" className="btn_primary">
            Get your id.cv
          </Link>

          <div className="other_actions">
            <a
              href="https://rebrand.ly/idcvwhitepaper"
              target="_blank"
              rel="noreferrer"
            >
              White Paper
            </a>
            <a href={telegramLink} target="_blank" rel="noreferrer">
              Join us on Telegram
            </a>
          </div>
        </div>
      </div>

      <div className="truly_yours">
        <div className="d_flex">
          <div className="info">
            <h4>A CV that’s truly yours. Not owned by LinkedIn or Indeed.</h4>
            <p>
              🔐 Decentralized and Secure on distributed IPFS.
              <br />
              💼 Store profile data, credentials and badges.
              <br />
              🧑🏼‍💻 Memorable ENS-ready Address.
              <br />
              🙋🏽‍♀️ Owner-controlled. You decide who sees what, and for how long.
              <br />
              💰 Earn as utility increases.
              <br />
              🌏 id.cv url accessible on and off chain.
              <br />
            </p>

            <Link to="/register" className="btn_primary">
              Get your id.cv <Icon name="arrow" />
            </Link>
          </div>

          <img src={secure} alt="Security Img" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
