import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ProtectedRoute } from "./components/protected-route";
import DashboardPages from "./dashboard";

import HomePage from "./pages/index";
import PrivacyPage from "./pages/privacy";
import RegisterPage from "./pages/register";
import LoginPage from "./pages/login";

import Alert from "./components/alert";

const App = () => {
  const { alert } = useSelector((state: any) => state.componentsSlice);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <ProtectedRoute component={DashboardPages} />
        </Switch>
      </BrowserRouter>

      {/* Other components */}
      {alert?.show && (
        <Alert
          show={alert?.show}
          className={alert?.type}
          text={alert?.message}
        />
      )}
    </React.Fragment>
  );
};

export default App;
