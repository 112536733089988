import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { API_URLS } from "../../api";

import { setAlert } from "./components-slice";

import { APP_USER } from "../../utils/constants";
import { useStorage, AnyObject, getRequestError } from "../../utils/functions";

type authSliceProps = {
  user: AnyObject | null;
};
const initialState: authSliceProps = {
  user: useStorage.get(APP_USER),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.user = payload;
      useStorage.set(APP_USER, payload);
    },
    logoutSuccess: (state) => {
      state.user = null;
      useStorage.remove(APP_USER);
      window.location.reload();
    },
  },
});

export const { loginSuccess, logoutSuccess } = authSlice.actions;

export const loginUserAction = (data: any) => async (dispatch: any) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URLS?.loginUser}`,
      { walletAddress: data?.address },
      {
        headers: {
          Authorization: `Bearer ${data?.accessToken}`,
          Accept: "application/json",
        },
      }
    );

    const successRes = res?.status === 200;
    if (successRes) {
      dispatch(loginSuccess(res?.data));
      dispatch(setAlert(true, "success", "Login successful!"));
    }
    return { success: successRes ? true : false };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));
  }
};

export const logoutUserAction = () => async (dispatch: any) => {
  dispatch(logoutSuccess());
  dispatch(setAlert(true, "success", "Logout successful!"));
  return { success: true };
};

export default authSlice.reducer;
