const AuthSide = () => {
  return (
    <div className="auth_side_container">
      <div className="animated_text">
        <p>
          Everyone is welcome here 🖤 <span>✦</span> Everyone is welcome here 🖤{" "}
          <span>✦</span> Everyone is welcome here 🖤 <span>✦</span> Everyone is
          welcome here 🖤 <span>✦</span> Everyone is welcome here 🖤{" "}
          <span>✦</span> Everyone is welcome here 🖤 <span>✦</span> Everyone is
          welcome here 🖤 <span>✦</span>
        </p>
      </div>

      <div className="occupations"></div>
    </div>
  );
};

export default AuthSide;
