import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";

import FormInput from "../../components/form-input";
import Button from "../../components/button";
import Loader from "../../components/loader";
import DropDown from "../../components/dropdown";

import {
  getMyDomainsAction,
  getDomainAvailabilityAction,
  postPrebookDomainAction,
  getPaymentStatusAction,
  getNFTAction,
} from "../../redux/slices/requests-slice";

import { empty } from "../../assets/img";
import Icon from "../../assets/svg";

import { AnyObject, useStorage, getRandomString } from "../../utils/functions";
import { APP_DOMAIN_PAY_INFO } from "../../utils/constants";

const ReservePage = () => {
  const account = useAccount();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useSelector((state: any) => state.authSlice);
  const { myDomains } = useSelector((state: any) => state.requestsSlice);

  const [view, setView] = useState(1);
  const [domain, setDomain] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const query = new URLSearchParams(history.location.search);
  const verifyQuery = query.get("verify");

  useEffect(() => {
    user && getDomains();

    if (verifyQuery) {
      checkPaymentStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDomains = async () => {
    setIsFetching(true);

    await dispatch(getMyDomainsAction());

    setIsFetching(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchResult) setSearchResult({});

    setDomain(e.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      searchDomain();
    }
  };

  const searchDomain = async () => {
    if (domain) {
      setIsSearching(true);

      await dispatch(getDomainAvailabilityAction(domain)).then((res) =>
        setSearchResult(res?.data)
      );

      setIsSearching(false);
    }
  };

  const prebookDomain = async () => {
    setIsSubmitting(true);

    await dispatch(
      postPrebookDomainAction({
        domain: domain,
        customer_wallet_address: account?.address,
        customer_email: user?.user?.email,
        redirect_url: `${window.location.href}?verify=true`,
        cancel_url: window.location.href,
      })
    ).then((res) => {
      if (res?.success) {
        useStorage.set(APP_DOMAIN_PAY_INFO, {
          domain,
          paymentId: res?.data?.paymentId,
        });
        window.location.assign(res?.data?.paymentLink);
      } else {
        setIsSubmitting(false);
      }
    });
  };

  const checkPaymentStatus = async () => {
    const paymentInfo = useStorage.get(APP_DOMAIN_PAY_INFO);

    if (paymentInfo?.paymentId) {
      setView(2);
      setIsVerifying(true);

      await dispatch(getPaymentStatusAction(paymentInfo?.paymentId)).then(
        (res) => {
          if (res?.success && res?.data?.paymentStatus === "success") {
            useStorage.remove(APP_DOMAIN_PAY_INFO);
            getDomains();
          }
        }
      );

      setView(1);
      setIsVerifying(false);
    }
  };

  const getNftUri = async (domain: string) => {
    setIsFetching(true);

    await dispatch(getNFTAction(domain)).then((res) => {
      if (res?.success) {
        downloadNFT(res?.data);
      }
    });

    setIsFetching(false);
  };

  const downloadNFT = (nft: any) => {
    // Create a link element
    const link = document.createElement("a");
    link.href = nft.image; // Set the href to the base64 image data
    link.download = `${nft.name}.svg`; // Set the download attribute with a file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const myDomainsList: any = myDomains?.filter(
    (x: AnyObject) => x?.paymentStatus !== "created"
  );

  const tableHeaders = [
    "Domain Name",
    "Status",
    "Minted",
    "Date Reserved",
    "Action",
  ];

  return (
    <div className="page_container reserve_page_container">
      {view === 1 && (
        <div className="my_domains">
          <div className="header">
            <div className="info">
              <h6>My Domains</h6>
              <p>View and manage all your registered domains.</p>
            </div>

            <div className="add" onClick={() => setView(2)}>
              <div className="icon">
                <Icon name="plus" />
              </div>
            </div>
          </div>

          <div className="vertical_scroll">
            <table className="table">
              <thead>
                <tr>
                  {tableHeaders?.map((item, i) => (
                    <td key={i}>{item}</td>
                  ))}
                </tr>
              </thead>

              <tbody>
                {myDomainsList?.length > 0 &&
                  myDomainsList?.map((item: AnyObject, i: number) => (
                    <tr key={i} className="item">
                      <td>
                        <p className="name">{item?.domain}</p>
                      </td>
                      <td>
                        <p className="expires payment">{item?.paymentStatus}</p>
                      </td>
                      <td>
                        <p className="expires">
                          {item?.isPrebookMinted ? "YES" : "NO"}
                        </p>
                      </td>
                      <td>
                        <p className="expires">
                          {new Date(item?.updatedAt).toDateString()}
                        </p>
                      </td>
                      <td>
                        <DropDown
                          toggler={
                            <div className="action">
                              Manage
                              <Icon name="chevronRight" />
                            </div>
                          }
                        >
                          <div
                            className="dropdown-item"
                            onClick={() => getNftUri(item?.domain)}
                          >
                            Get NFT
                          </div>
                        </DropDown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {isFetching ? (
              <Loader />
            ) : (
              myDomainsList?.length < 1 && (
                <div className="no_result">
                  <img src={empty} alt="No result" />
                  <p>
                    No registered domains found for this account. Tap the “ + “
                    icon to register domain.
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      )}

      {view === 2 && (
        <div className="register_domain">
          <div className="go_back">
            <div className="icon" onClick={() => setView(1)}>
              <Icon name="arrow" />
            </div>
          </div>

          <div className="register_content">
            <h4 className="title">Reserve a Domain</h4>
            <p className="sub_title">
              Reserve to mint id.cv soul bound NFT and get IDCV token airdrops
              when we launch.
            </p>

            {isVerifying ? (
              <div className="verifying_minting">
                <Loader text="Please wait while we verify your payment..." />
              </div>
            ) : (
              <>
                <div className="d_flex">
                  <FormInput
                    label="Choose your id.cv name"
                    type="text"
                    placeholder={getRandomString()}
                    value={domain}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    readOnly={isSearching || isSubmitting}
                  />
                  <Button
                    onClick={isSearching ? () => {} : searchDomain}
                    disabled={isSearching || isSubmitting}
                    loading={isSearching}
                  >
                    <Icon name="search" />
                  </Button>
                </div>

                {searchResult?.domain && (
                  <>
                    <div className="domain_preview">
                      <div className="icon">
                        <Icon name="cart" />
                      </div>

                      <div className="info">
                        <h6>Domain: {searchResult?.domain}</h6>
                        <p>Cost: {searchResult?.price} USDC</p>
                      </div>
                    </div>

                    <div className="actions">
                      <Button
                        onClick={prebookDomain}
                        disabled={!searchResult?.available}
                        loading={isSubmitting}
                      >
                        {searchResult?.available
                          ? "Prebook Domain"
                          : "Not available"}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservePage;
