import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux";

import AuthSide from "../components/auth-side";
import GoogleAuth from "../components/google-auth";

import { loginUserAction } from "../redux/slices/auth-slice";

import Icon from "../assets/svg";

const LoginPage = () => {
  const account: any = useAccount();
  const { open } = useWeb3Modal();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useSelector((state: any) => state.authSlice);

  const [accessToken, setAccessToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const authWithGoogle = async (access_token: any) => {
    setAccessToken(access_token);

    if (!account?.address) open();
  };

  useEffect(() => {
    if (!user && accessToken && account?.address && isSubmitting) {
      loginUser(account.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, accessToken]);

  const loginUser = async (address: string) => {
    if (accessToken && address) {
      const payload = { accessToken, address };

      await dispatch(loginUserAction(payload));

      setIsSubmitting(false);
    }
  };

  if (user) {
    return <>{history.push("/reserve")}</>;
  }

  return (
    <div className="auth_page_container">
      <AuthSide />

      <div className="page_content">
        <div className="top">
          <Link to="/" className="icon close">
            <Icon name="close" />
          </Link>
        </div>

        <div className="form_container">
          <p className="alread_text">
            New? <Link to="/register">Create Account</Link>
          </p>
          <h4 className="title">Welcome</h4>
          <p className="sub_title">Let’s continue from where you left-off</p>

          <GoogleAuth
            isLogin={true}
            onSubmit={(profile: any) => authWithGoogle(profile)}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
          <p className="and_text">
            and <span>Connect Wallet</span>
          </p>
        </div>

        <div className="top"></div>
      </div>
    </div>
  );
};

export default LoginPage;
