import axios from "axios";

import { getUserDetails } from "../utils/functions";

export const API_URLS = {
  loginUser: `/auth/login`,
  getDomains: `/domain/all`,
  getDomainAvailability: `/domain/availability`,
  prebookDomain: `/domain/prebook`,
  getPaymentStatus: `/domain/payment-status`,
  mintDomain: `/domain/mint`,
  getNft: `/domain/prebook/uri`,
};

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

// Request interceptors for API calls
API.interceptors.request.use(
  async (config: any) => {
    const token = getUserDetails()?.token;

    config.headers = {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : ``,
    };
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

type GetRequestProps = {
  url: string;
  params: any;
};
export const getRequest = async ({ url, params }: GetRequestProps) => {
  const requestResponse = await API({
    url,
    method: "get",
    params,
  });

  return requestResponse;
};

type PostRequestProps = {
  url: string;
  params: any;
  data: any;
  formData?: any;
};
export const postRequest = async ({
  url,
  params,
  data,
  formData,
}: PostRequestProps) => {
  const requestResponse = await API({
    headers: {
      "Content-Type": formData ? "multipart/form-data" : "application/json",
    },
    url,
    method: "post",
    params,
    data,
  });

  return requestResponse;
};

type PatchRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const patchRequest = async ({
  url,
  params,
  data,
}: PatchRequestProps) => {
  const requestResponse = await API({
    url,
    method: "patch",
    params,
    data,
  });

  return requestResponse;
};

type DeleteRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const deleteRequest = async ({
  url,
  params,
  data,
}: DeleteRequestProps) => {
  const requestResponse = await API({
    url,
    method: "delete",
    params,
    data,
  });

  return requestResponse;
};
