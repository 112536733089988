export const Google = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22933_237281)">
      <path
        d="M8.86104 0.789433C6.46307 1.62131 4.39506 3.20024 2.96077 5.29431C1.52649 7.38838 0.801526 9.8872 0.892371 12.4237C0.983217 14.9603 1.88508 17.4008 3.4655 19.3869C5.04591 21.373 7.22158 22.8 9.67292 23.4582C11.6603 23.971 13.7424 23.9935 15.7404 23.5238C17.5504 23.1173 19.2238 22.2476 20.5967 21.0001C22.0256 19.662 23.0627 17.9597 23.5967 16.0763C24.1768 14.0282 24.2801 11.8743 23.8985 9.78006H12.7385V14.4094H19.2017C19.0725 15.1478 18.7957 15.8525 18.3878 16.4814C17.98 17.1102 17.4494 17.6504 16.8279 18.0694C16.0388 18.5917 15.1491 18.943 14.216 19.1007C13.2803 19.2747 12.3205 19.2747 11.3848 19.1007C10.4363 18.9048 9.53912 18.5134 8.75042 17.9513C7.4832 17.0543 6.53168 15.7799 6.03167 14.3101C5.52333 12.8126 5.52333 11.1893 6.03167 9.69193C6.38759 8.64234 6.97598 7.68669 7.75292 6.89631C8.64203 5.97521 9.76766 5.3168 11.0063 4.99333C12.245 4.66985 13.5488 4.6938 14.7748 5.06256C15.7325 5.35641 16.6083 5.87008 17.3323 6.56256C18.061 5.83756 18.7885 5.11068 19.5148 4.38193C19.8898 3.99006 20.2985 3.61693 20.6679 3.21568C19.5627 2.18728 18.2654 1.387 16.8504 0.860683C14.2736 -0.0749616 11.4541 -0.100106 8.86104 0.789433Z"
        fill="white"
      />
      <path
        d="M8.8607 0.789367C11.4536 -0.100776 14.2731 -0.0762934 16.8501 0.858742C18.2653 1.38864 19.562 2.19277 20.6657 3.22499C20.2907 3.62624 19.8951 4.00124 19.5126 4.39124C18.7851 5.11749 18.0582 5.84124 17.332 6.56249C16.608 5.87001 15.7322 5.35635 14.7745 5.06249C13.5489 4.69244 12.2451 4.66711 11.0061 4.98926C9.76712 5.31141 8.64079 5.96861 7.7507 6.88874C6.97377 7.67912 6.38538 8.63477 6.02945 9.68437L2.14258 6.67499C3.53384 3.91604 5.94273 1.80566 8.8607 0.789367Z"
        fill="#E33629"
      />
      <path
        d="M1.1114 9.6563C1.32016 8.62087 1.66701 7.61816 2.14265 6.67505L6.02953 9.69192C5.52119 11.1893 5.52119 12.8126 6.02953 14.31C4.73453 15.31 3.4389 16.315 2.14265 17.325C0.952308 14.9556 0.589275 12.256 1.1114 9.6563Z"
        fill="#F8BD00"
      />
      <path
        d="M12.7372 9.77808H23.8972C24.2787 11.8723 24.1754 14.0262 23.5953 16.0743C23.0614 17.9577 22.0242 19.66 20.5953 20.9981C19.3409 20.0193 18.0809 19.0481 16.8265 18.0693C17.4484 17.6498 17.9792 17.1091 18.3871 16.4796C18.795 15.85 19.0716 15.1446 19.2003 14.4056H12.7372C12.7353 12.8643 12.7372 11.3212 12.7372 9.77808Z"
        fill="#587DBD"
      />
      <path
        d="M2.14062 17.3251C3.43687 16.3251 4.7325 15.3201 6.0275 14.3101C6.52851 15.7804 7.48138 17.0549 8.75 17.9513C9.54116 18.5107 10.4403 18.899 11.39 19.0913C12.3257 19.2653 13.2855 19.2653 14.2213 19.0913C15.1543 18.9336 16.044 18.5823 16.8331 18.0601C18.0875 19.0388 19.3475 20.0101 20.6019 20.9888C19.2292 22.237 17.5558 23.1073 15.7456 23.5144C13.7476 23.9841 11.6655 23.9616 9.67813 23.4488C8.10632 23.0291 6.63814 22.2893 5.36563 21.2757C4.01886 20.2062 2.91882 18.8587 2.14062 17.3251Z"
        fill="#319F43"
      />
    </g>
    <defs>
      <clipPath id="clip0_22933_237281">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
