import Loader from "./loader";

import Icon from "../assets/svg";

type Props = {
  className?: string;
  leftIcon?: string;
  rightIcon?: string;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  className,
  leftIcon,
  rightIcon,
  loading,
  loadingText,
  disabled,
  children,
  ...rest
}: Props) => (
  <button
    className={`btn button_component ${className ? className : "btn_primary"} ${
      loading ? "loading" : ""
    }`}
    disabled={disabled || loading}
    {...rest}
  >
    {loading ? (
      <div className="loader">
        <Loader />
        {loadingText && <p>{loadingText}</p>}
      </div>
    ) : (
      <>
        {leftIcon && <Icon name={leftIcon} />}
        {children}
        {rightIcon && <Icon name={rightIcon} />}
      </>
    )}
  </button>
);

export default Button;
