import { createSlice } from "@reduxjs/toolkit";

type Props = {
  alert: {
    show: boolean;
    type: string;
    message: string;
  };
};
export const initialState: Props = {
  alert: {
    show: false,
    type: "",
    message: "",
  },
};

// Slice
const slice = createSlice({
  name: "components",
  initialState,
  reducers: {
    IsShowingAlert: (state, { payload }) => {
      state.alert = payload;
    },
  },
});
export default slice.reducer;

// Actions
const { IsShowingAlert } = slice.actions;

export const setAlert =
  (show: any, type: any, message: any) => (dispatch: any) => {
    dispatch(
      IsShowingAlert({
        show,
        type,
        message,
      })
    );
  };

export const closeAlert = () => (dispatch: any) => {
  dispatch(IsShowingAlert({}));
};
