/* eslint-disable no-fallthrough */
import { APP_USER } from "./constants";

// Storage operations
export const useStorage = {
  set: (key: string, data: any) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key: string) => {
    const data: any = JSON.parse(localStorage.getItem(key)!);

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key: string) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const getUserDetails = () => {
  const user = useStorage.get(APP_USER);

  return user ? user : null;
};

export const loginUserSuccess = (data: any) => {
  useStorage.set(APP_USER, data);
};

export const logoutUserAction = () => {
  useStorage.remove(APP_USER);
  window.location.reload();
};

export const getRequestError = (error: any) => {
  const { response } = error;

  if (response && typeof response?.data === "string") {
    return response.data;
  } else if (response && response?.data?.msg) {
    return response.data.msg;
  }
  return "There might be a problem with your internet connection. Please check and try again.";
};

export const socialOnClick = (social: { name: string; handle: string }) => {
  switch (social.name) {
    case "whatsapp":
      window.open(
        `https://api.whatsapp.com/send?phone=${social.handle}&text=Hello! I'm a website visitor.`
      );
      break;
    case "twitter":
      window.open(`https://x.com/${social.handle}`);
      break;
    case "instagram":
      window.open(`https://www.instagram.com/${social.handle}`);
      break;
    case "facebook":
      window.open(`https://facebook.com/${social.handle}`);
      break;
    case "tiktok":
      window.open(`https://www.tiktok.com/${social.handle}`);
      break;
    default:
      return;
  }
};

export type AnyObject = { [key: string]: any };

export const emailFormValidation = (required: boolean) => {
  return {
    required: required ? "Email address is required" : false,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  };
};
export const textFormValidation = (required: boolean, minLength?: number) => {
  return {
    required: required ? "This field is required" : false,
    minLength: minLength
      ? {
          value: minLength + 1,
          message: `Must be more than ${minLength} character(s)`,
        }
      : {
          value: 2,
          message: "Must be more than 1 character",
        },
  };
};
export const passwordFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: 8,
      message: "Must use at least 8 characters",
    },
  };
};

export function getRandomString(): string {
  const strings: string[] = [
    "sikka",
    "micaac",
    "opaang",
    "safolayan",
    "doe",
    "digitalmike",
    "defimark",
    "ga",
    "nick",
    "tina",
  ];

  const randomIndex = Math.floor(Math.random() * strings.length);
  return strings[randomIndex];
}
